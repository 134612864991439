<template>
<div>
  <a-spin :spinning="!inited" tip="Loading...">
    <div v-show="inited">
      <el-dialog
          :title="$t('care.invitationform.add')"
          :visible.sync="addaddInvitationVisible"
          append-to-body>
        <el-form :model="form"  :label-width="formLabelWidth" style="max-width: 800px">
          <el-form-item :label="$t('care.invitationform.identity')" prop="identity">
            <div class="formval">
              <el-input  v-model="form.identity"></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addaddInvitationVisible = false">{{$t('care.messenger.cancel')}}</el-button>
          <el-button :loading="addInvitationLoading" type="primary" @click="handleAddOk">{{$t('care.invitationform.save')}}</el-button>
        </div>
      </el-dialog>
      <template>
        <div class="invitationlist" >
          <div class="header">
            <el-button size="mini" plain type="primary" @click="()=>this.$router.back()">{{$t("care.back")}}</el-button>
            <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="getList()"></el-button>
            <el-button size="mini"  plain type="primary" icon="el-icon-plus"  @click="handleAdd()">{{$t("care.addInvitation")}}</el-button>
            <div class="search">
              <span>{{$t("care.search_title")}}：</span>
              <div>
                <el-select @change="onSearch" clearable size="small" style="width: 100px" v-model="search.status" :placeholder="$t('care.value_str.select')">
                  <el-option :label="$t('care.invite_status.sending')" :value="1"></el-option>
                  <el-option :label="$t('care.invite_status.success')" :value="2"></el-option>
                </el-select>
              </div>
              <div>
                <el-input size="small"  :placeholder="$t('care.search_title')" v-model="search.identity" class="input-with-select">
                  <el-button  @click="onSearch" slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
            </div>
          </div>
          <div class="content">
            <el-table
                class="table"
                v-loading="spinning"
                :data="tableData"
                :height="tableHeight"
                highlight-current-row
            >
              <el-table-column
                  prop="identity"
                  :label="$t('care.identity')"
              >
              </el-table-column>
              <el-table-column
                  prop="status"
                  :label="$t('care.status')"
              >
                <template slot-scope="scope">
                  <div>{{getStatusStr(scope.row.status)}}</div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="url"
                  :label="$t('care.url')"
              >
                <template slot-scope="scope">
                  <div>
                    <a @click="copyLink(scope.row.url)" href="javascript:void(0);">{{$t('care.open_url')}}</a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="created_at"
                  :label="$t('care.created_at')"
              >
              </el-table-column>
              <el-table-column
                  :label="$t('care.ops')"
              >
                <template slot-scope="scope">
                  <div class="options">
                    <div>
                      <el-button
                          :title="$t('care.delete_invitation')"
                          size="mini"
                          type="danger" plain
                          icon="el-icon-delete"
                          @click="handleDelete(scope.$index, scope.row)"></el-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                small
                layout="prev, pager, next"
                :current-page.sync="page"
                :page-size="pageSize"
                @current-change="pageChange"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </template>
      <OrderRenewal @onClose="order_status_result_visible=false" :group_id="group_id" :visible="order_status_result_visible" :title="order_status_title"></OrderRenewal>
    </div>

  </a-spin>

</div>
</template>

<script>
import "./index.less";
import careMixin from "../../../mixins/careMixin";
import {addInvitation, deleteInvitation, getInvitations} from "../../../libs/apis/invitation";
import {getAvailableStorage} from "../../../mixins/AvailableStorage";
import linkMixin from "../../../mixins/linkMixin";
import orderStateResultMixin from "../../../mixins/orderStateResultMixin";
import OrderRenewal from "@/pages/admin/components/OrderRenewal.vue";
import {code_need_pay,code_need_renewal} from "../../../config/customCode";
const {sessionStorage}= getAvailableStorage();
export default {
  name: "Invitationlist",
  components: {
    OrderRenewal,
  },
  data(){
    return {
      inited:false,
      deleteLoading:false,
      addaddInvitationVisible:false,
      addInvitationLoading:false,
      delete_id:null,
      visible:false,
      editVisible:false,
      editRow:{},
      formLabelWidth:'120px',
      spinning:false,
      tableHeight:document.documentElement.clientHeight - 100,
      page:1,
      total:0,
      pageSize:12,
      tableData:[],
      deleteRow:{},
      hadGetlist:false,
      user_pool_id:null,
      form:{
        identity:null,
      },
      search:{
        identity:null,
        status:null,
      },
    };
  },
  mounted() {
    let user_pool_id=this.$route.params.user_pool_id;
    if(!user_pool_id){
      user_pool_id=sessionStorage.getItem('user_pool_id');
    }
    this.user_pool_id=user_pool_id;
    this.$nextTick(function () {
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight = document.documentElement.clientHeight - 100;
      }
      this.getList();
    });
  },
  methods:{
    getStatusStr(status){
      if(status==1){
        return this.$t('care.invite_status.sending');
      }else if(status==2){
        return this.$t('care.invite_status.success');
      }else{
        return this.$t('care.invite_status.other');
      }
    },
    handleAdd(){
      this.addaddInvitationVisible=true;
    },
    async handleDelete(index, row){
      try {
        await deleteInvitation(this.group_id,row.id);
      }catch (e) {
        this.$message.error(e.data?.message||e.message);
        throw e;
      }

      this.getList();

    },
    async handleAddOk(){
      try {
        await addInvitation(this.group_id,this.form.identity,this.user_pool_id);
        this.addaddInvitationVisible=false;
        this.form.identity=null;
      }catch (e) {
        if(e.code===code_need_pay||e.code===code_need_renewal){
            this.order_status_result_visible=true;
            this.order_status_title=e.message;
        }
        await this.$message.error(e.message, 0);
        return;
      }

      this.getList();
    },
    copyLink(url){
      this.copy(url)
    },
    onSearch(){
      this.page=1;
      this.getList();
    },
    pageChange(page){
      this.page=page;
      this.getList();
    },
    async getList(){
      this.spinning=true;
      // 查询该分组下的邀请
      const rs=await getInvitations(this.user_pool_id,{
        filter:{
          ...this.search,
          'group_id':this.group_id
        },
        page:{
          number:this.page,
          size:this.pageSize,
        }
      });
      this.tableData=rs.data;
      this.total= rs.total;
      this.inited=true;
      this.hadGetlist=true;
      this.spinning=false;

    },
  },
  mixins:[careMixin,linkMixin,orderStateResultMixin]
}
</script>

<style scoped>

</style>